import React , {Fragment, useEffect, useState} from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from '../../components/layout/index';
import Blog from '../../components/blog/index';
import BreadCrumbs from '../../components/breadCrumbs/index';
import "./index.css";

import  { getDate } from '../../utilities/utilfns';

function CardBlogPage({data}){
    const {contentfulSimplyFiBlogPage = {}, allContentfulSimplyFiBlogPage = {}} = data;
    const {title, subTitle , introduction , body , slug, conclusion , alsoread, updatedAt} = contentfulSimplyFiBlogPage;
    const [location, setLocation] = useState({});
    const [alsoReadList, setAlsoRead] = useState([]);
    const date = new Date(updatedAt);

    
    useEffect( () => {
      setLocation(global.location);
      const basePath = location.origin + "/" +  global.location.pathname.replaceAll("/" , " ").trim().split(" ")
      .slice(0,-1).reduce( (acc, item) => {  acc += item + "/"; return acc; } , "" );
      const alsoReadList = allContentfulSimplyFiBlogPage.nodes.filter(ele => alsoread.map(item => item.content).indexOf(ele.slug) > -1 );
      setAlsoRead(alsoReadList.map(ele => ({...ele , url : basePath + ele.slug})))
      // setAlsoRead( alsoread.map(ele => basePath + ele) );
    } , [global.location ] )


  

    return (
        <Layout>
          <div className="blogs-container" key={slug}>
            <BreadCrumbs location={location}/>
            <div style={{color : "#333", fontSize : "200%", fontWeight:700}}> {title} </div>
            <div style={{margin : "1rem 0", fontSize : "105%"}}> {subTitle.subTitle} </div>
            <div style={{color : "grey", fontWeight : "600", fontSize : "0.8rem"}}> { "Published on :  "  + getDate(date)}  </div>
            <div dangerouslySetInnerHTML={{__html : `${introduction.introduction}`}} />
            <div dangerouslySetInnerHTML={{__html : `${body.body}`}} />
            <div className="group-data-container">
              <div className="group-data-title"><div className="category-title"> {"Conclusion"} </div></div>
              <div style={{padding: "0.5rem"}} dangerouslySetInnerHTML={{__html : `${conclusion.conclusion}`}} />
            </div>
            <div className="also-read"> You may also like  </div>
            <div className="also-read-hr"/> 
            <div className="also-read-list">
              {alsoReadList.map(ele => <Blog blog={ele} />)}
            </div>
          </div> 
      </Layout> 
    )

}

export const Head = ({data}) => {
    const {contentfulSimplyFiBlogPage = {}} = data;

    return (
    <Fragment>
        <title> {contentfulSimplyFiBlogPage.title  } </title>
        {/* <meta name="description"  content={content} /> */}
    </Fragment>)
}

export default CardBlogPage;

export const query = graphql`
query ($slug: String) {
  contentfulSimplyFiBlogPage(slug: {eq: $slug}) {
    title
    updatedAt
    subTitle {
      subTitle
    }
    introduction {
      introduction
    }
    body {
      body
    }
    slug
    conclusion {
      conclusion
    }
    alsoread {
      content
    }
  }
  allContentfulSimplyFiBlogPage {
    nodes {
      slug
      title
      subTitle {
        subTitle
      }
      updatedAt
    }
  }
}
`